import axios, { authAxios } from "./../../config/axios";

class Auth {
  login = (data) => {
    return axios.post("/administration/auth/login/admin", data);
  };

  check = () => {
    return authAxios.post("/administration/auth/check");
  };

  logout = () => {
    return authAxios.post("/administration/auth/logout");
  };

  forgetPassword = (email) => {
    return axios.post("/administration/auth/recover-password-web", email);
  };

  ChangePassword = (data) => {
    return axios.post("/administration/auth/change-password", data);
  };
}

export default new Auth();
