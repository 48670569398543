import { createReducer } from "@reduxjs/toolkit";
import types from "./actionTypes";

const initialState = {
  users: [],
  trash: [],
  wallets: [],
  histories: [],
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(types.setUsers, (state, action) => {
      state.users = action.payload;
    })

    .addCase(types.setTrash, (state, action) => {
      state.trash = action.payload;
    })

    .addCase(types.setWallets, (state, action) => {
      state.wallets = action.payload;
    })
    .addCase(types.setHistories, (state, action) => {
      state.histories = action.payload;
    });
});
